import React from 'react'
import Layout from '../layouts'
import VehicleCheckoutContent from "../components/vehicle-checkout-components/vehicle-checkout-components";
import { checkTheme } from '../utils/informations'

const CheckoutForms = () => {
    
    return (
        <Layout pageTitle={`Checkout - ${checkTheme().pageTitle}`}>
            <VehicleCheckoutContent />
            

        </Layout>
    )
}

export default CheckoutForms
